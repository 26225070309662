
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  font-size: 1.6rem;
}

h1{
  font-size: 6rem;
}

.app{
  width: 100%;
  height: 100vh;
  position: relative;
  background: rgba(0,0,0,0.4);
  color: #fff;
}

.app::before{
  content: '';
  background: url('./assets/wi3.jpg') no-repeat center center/cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.app .search{
  text-align: center;
  padding: 1rem;
}

.app input{
  padding: .7rem 1.5rem;
  font-size: 1.2em;
  border-radius: 25px;
  border: 1px solid rgba(255, 255 , 255,0.8);
  background: rgba(255,255,255,0.1);
  color: #f8f8f8;
}
::placeholder{
  color: #f8f8f8;
}
.container{
  max-width: 700px;
  height: 525px;
  margin: auto;
  padding: 0n 1rem;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app .top {
  width: 100%;
  margin: 1rem auto;
}

.app .description{
  position: relative;
  right: -90%;
  transform-origin: 0 0;
  transform: rotate(269deg);

}

.app .bottom{
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255,255,255,0.2);
}

.bold{
  font-weight: 700;
}
